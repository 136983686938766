import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        フリーのカメラマンとしてCMを中心に活動後、1994年モンタージュを設立。<br />
        その後も精力的に作品に携わり続け、「生涯現役」を目指す。<br />
        モットーは「目配り・気配り・心配り」
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return (
    <Container fluid className="memberAwards">
      <Row>
        <Col>
          <h3 className="titleTopBorder">Awards</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="awardsTitles">
            <Col xs={6} lg={6} className="d-none d-lg-block titleLabel">Works</Col>
            <Col xs={6} lg={6} className="d-none d-lg-block titleLabel">Award title</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">紀伊国屋書店「生きもの 金子兜太の世界」</Col>
            <Col xs={6} lg={6} className="col">映文連アワード2010 最優秀作品賞（グランプリ）</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">紀伊國屋書店評伝シリーズ「学問と情熱」本多静六</Col>
            <Col xs={6} lg={6} className="col">第50回科学技術映像祭 文部科学大臣賞</Col>
          </Row>
          <Row className="awardsCatBlock">
            <Col xs={6} lg={6} className="col">紀伊國屋書店評伝シリーズ「学問と情熱」寺田寅彦</Col>
            <Col xs={6} lg={6} className="col">第46回科学技術映像祭 文部科学大臣賞</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
